<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-sheet color="primary" dark class="pa-2">
        <v-flex xs12>
          <v-card-text
            class="w-block__body2"
            :class="{
              'pa-2': $voicer.isMobile === false,
              'pa-1': $voicer.isMobile === true,
              headline: $voicer.isMobile,
              title: $voicer.isMobile === false,
            }"
          >
            Écoutez ou réécoutez les épisodes de Patients Ensemble.
            Retrouvez-nous également sur les réseaux sociaux.
          </v-card-text>
          <p class="body-2">
            <v-btn
              icon
              color="white"
              @click="
                handleOpen(
                  'https://www.facebook.com/Patients-Ensemble-108805940936951'
                )
              "
            >
              <v-icon color="black"> mdi-facebook </v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
              @click="
                handleOpen('https://www.instagram.com/patientsensemble/?hl=fr')
              "
            >
              <v-icon color="black"> mdi-instagram </v-icon>
            </v-btn>
            <v-btn
              icon
              color="white"
              @click="
                handleOpen('https://www.linkedin.com/showcase/76940322/admin/')
              "
            >
              <v-icon color="black"> mdi-linkedin </v-icon>
            </v-btn>
          </p>
        </v-flex>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.v-sheet {
  border-radius: 8px;
}
p {
  color: white;
  text-align: center;
  font-size: 17px !important;
}
a {
  color: white;
}
.v-btn {
  justify-content: center;
  margin-top: 7px;
}
</style>
